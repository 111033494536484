<template>
  <div>
    <b-modal id="modal-primary" v-model="showPrint" no-close-on-backdrop ok-only :ok-title="$t('Close')"
      modal-class="modal-primary" centered :title="$t('Set Printing')" hide-footer>
      <b-card>
        <b-card-header>
          <name-tag :user-data="userData" />
        </b-card-header>
      </b-card>
      <b-card>
        <b-card-header>
          <b-card-header-title>
            <feather-icon icon="InfoIcon" size="18" />
            <span class="ml-2">{{ $t("Set Printing") }}</span>
          </b-card-header-title>
        </b-card-header>
        <b-card-body>
          <p class="">
            {{ $t("A set of 12 Exercises will be prepared") }}
          </p>
        </b-card-body>
      </b-card>
      <b-row style="justify-content: space-between">
        <b-button v-b-modal.modal-primary variant="primary" @click="printed()" :to="{
            name: 'print-exercise',
            params: { id: userID },
            query: { amountp: 12 },
          }" target="_blank">
          {{ $t("Print") }}
        </b-button>
        <b-button disabled variant="secondary">{{ $t("Online") }}</b-button>
      </b-row>
    </b-modal>
    <!-- <PrintExercise :getUserID="userID" :getAmount="3" v-if="false" /> -->
  </div>
</template>
<script>
import store from "@/store";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
/* import PrintExercise from "@/views/print-exercise/PrintExercise.vue"; */
import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BBadge,
  BLink,
  BModal,
  BCardText,
  BIcon,
  BCardBody,
  BCardHeader,
} from "bootstrap-vue";
import userStoreModule from "../userStoreModule";
import nameTag from "./nameTag.vue";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BTable,
    BFormCheckbox,
    BButton,
    BCard,
    BRow,
    BCol,
    BBadge,
    BLink,
    BCardText,
    vSelect,
    BIcon,
    BCardBody,
    BCardHeader,
    nameTag,
    /* PrintExercise, */
  },
  props: {
    userID: {
      type: String,
      required: false,
    },
    showPrint: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["printed"],
  data() {
    return {
      amount: 6,
      selected: "",
      options: [
        { text: "6", Number: 6 },
        { text: "7", Number: 7 },
        { text: "8", Number: 8 },
        { text: "9", Number: 9 },
        { text: "10", Number: 10 },
        { text: "11", Number: 11 },
        { text: "12", Number: 12 },
      ],
    };
  },
  methods: {
    selectedf() {
      console.log(this.selected.Number);
    },
    printed() {
      //console.log("printed");
      setTimeout(() => {
        this.$emit("printed");
      }, 1000);
    },
  },
  setup(props) {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    const sID = props.userID;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    store
      .dispatch("app-user/fetchUser", { id: sID })
      .then((response) => {
        userData.value = response.data.subject;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          console.log("error");
          userData.value = undefined;
        }
      });

    return {
      userData,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
