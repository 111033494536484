<template>
  <div>
    <user-exercise />
    <!-- <user-exercise-details /> -->
  </div>
</template>
<script>
import UserExercise from "@/views/apps/user/users-exercise/UsersExercise.vue";
// import UserExerciseDetails from '@/views/apps/user/users-exercise/UsersExerciseDetails.vue'

export default {
  components: {
    UserExercise,
    // UserExerciseDetails,
  },
};
</script>
