<template>
  <ul class="app-timeline" v-bind="$attrs" v-on="$listeners">
    <slot />
  </ul>
</template>

<script>
import { BButton, BCollapse, VBToggle } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BCollapse,
  },
  directives: {
    "b-toggle": VBToggle,
  },
};
</script>

<style lang="scss" scoped>
.app-timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-left: 1rem;
}
</style>
