var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("Subject"))+": "+_vm._s(_vm.userData.name.toUpperCase()))]),_c('ul',{staticClass:"group-list-on-print"},[_c('li',{staticClass:"print-group-list-item"},[_c('b',[_vm._v(_vm._s(_vm.$t("Attention"))+":")]),(_vm.userData.program.groups.attention.group)?_c('b-badge',{staticClass:"ml-1",attrs:{"id":"attention-print","variant":("light-" + (_vm.resolveUserRoleVariant(
        _vm.numberToGrade(_vm.userData.program.groups.attention.group)
      )))}},[_c('b-tooltip',{attrs:{"target":"attention-print","custom-class":"group-tooltip-wrapper","triggers":"hover"}},[_c('b-row',{staticClass:"justify-content-center"},_vm._l((_vm.userData.program.groups.attention.lastUpdate),function(exercise,index){return _c('b-badge',{key:'att' + index,staticStyle:{"margin":"5px"},attrs:{"variant":exercise.indexOf('Ø') != -1
                  ? 'dark'
                  : exercise.indexOf('F') != -1
                  ? 'danger'
                  : exercise.indexOf('E') != -1
                  ? 'warning'
                  : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                  ? 'info'
                  : 'success'}},[_vm._v(" "+_vm._s(exercise)+" ")])}),1)],1),_vm._v(" "+_vm._s(_vm.numberToGrade(_vm.userData.program.groups.attention.group))+" "),(
          _vm.userData.program.groups.attention.change[
            _vm.userData.program.groups.attention.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.attention.change[
            _vm.userData.program.groups.attention.change.length - 1
          ] > _vm.userData.program.groups.attention.group
        )?_c('feather-icon',{attrs:{"icon":"ArrowUpRightIcon"}}):(
          _vm.userData.program.groups.attention.change[
            _vm.userData.program.groups.attention.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.attention.change[
            _vm.userData.program.groups.attention.change.length - 1
          ] < _vm.userData.program.groups.attention.group
        )?_c('feather-icon',{attrs:{"icon":"ArrowDownRightIcon"}}):(
          _vm.userData.program.groups.attention.change[
            _vm.userData.program.groups.attention.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.attention.change[
            _vm.userData.program.groups.attention.change.length - 1
          ] == _vm.userData.program.groups.attention.group
        )?_c('feather-icon',{attrs:{"icon":"CodeIcon"}}):_vm._e()],1):_vm._e()],1),_c('li',{staticClass:"print-group-list-item"},[_c('b',[_vm._v(_vm._s(_vm.$t("Logic"))+":")]),(_vm.userData.program.groups.logic.group)?_c('b-badge',{staticClass:"ml-1",attrs:{"id":"logic-print","variant":("light-" + (_vm.resolveUserRoleVariant(
        _vm.numberToGrade(_vm.userData.program.groups.logic.group)
      )))}},[_c('b-tooltip',{attrs:{"target":"logic-print","triggers":"hover","custom-class":"group-tooltip-wrapper"}},[_c('b-row',{staticClass:"justify-content-center"},_vm._l((_vm.userData.program.groups.logic.lastUpdate),function(exercise,index){return _c('b-badge',{key:'logic' + index,staticStyle:{"margin":"5px"},attrs:{"variant":exercise.indexOf('Ø') != -1
                  ? 'dark'
                  : exercise.indexOf('F') != -1
                  ? 'danger'
                  : exercise.indexOf('E') != -1
                  ? 'warning'
                  : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                  ? 'info'
                  : 'success'}},[_vm._v(" "+_vm._s(exercise)+" ")])}),1)],1),_vm._v(" "+_vm._s(_vm.numberToGrade(_vm.userData.program.groups.logic.group))+" "),(
          _vm.userData.program.groups.logic.change[
            _vm.userData.program.groups.logic.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.logic.change[
            _vm.userData.program.groups.logic.change.length - 1
          ] > _vm.userData.program.groups.logic.group
        )?_c('feather-icon',{attrs:{"icon":"ArrowUpRightIcon"}}):(
          _vm.userData.program.groups.logic.change[
            _vm.userData.program.groups.logic.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.logic.change[
            _vm.userData.program.groups.logic.change.length - 1
          ] < _vm.userData.program.groups.logic.group
        )?_c('feather-icon',{attrs:{"icon":"ArrowDownRightIcon"}}):(
          _vm.userData.program.groups.logic.change[
            _vm.userData.program.groups.logic.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.logic.change[
            _vm.userData.program.groups.logic.change.length - 1
          ] == _vm.userData.program.groups.logic.group
        )?_c('feather-icon',{attrs:{"icon":"CodeIcon"}}):_vm._e()],1):_vm._e()],1),_c('li',{staticClass:"print-group-list-item"},[_c('b',[_vm._v(_vm._s(_vm.$t("Concentration"))+":")]),(_vm.userData.program.groups.concentration.group)?_c('b-badge',{staticClass:"ml-1",attrs:{"id":"concentration-print","variant":("light-" + (_vm.resolveUserRoleVariant(
        _vm.numberToGrade(_vm.userData.program.groups.concentration.group)
      )))}},[_c('b-tooltip',{attrs:{"target":"concentration-print","triggers":"hover","custom-class":"group-tooltip-wrapper"}},[_c('b-row',{staticClass:"justify-content-center"},_vm._l((_vm.userData.program.groups.concentration
            .lastUpdate),function(exercise,index){return _c('b-badge',{key:'conc' + index,staticStyle:{"margin":"5px"},attrs:{"variant":exercise.indexOf('Ø') != -1
                    ? 'dark'
                    : exercise.indexOf('F') != -1
                    ? 'danger'
                    : exercise.indexOf('E') != -1
                    ? 'warning'
                    : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                    ? 'info'
                    : 'success'}},[_vm._v(" "+_vm._s(exercise)+" ")])}),1)],1),_vm._v(" "+_vm._s(_vm.numberToGrade(_vm.userData.program.groups.concentration.group))+" "),(
          _vm.userData.program.groups.concentration.change[
            _vm.userData.program.groups.concentration.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.concentration.change[
            _vm.userData.program.groups.concentration.change.length - 1
          ] > _vm.userData.program.groups.concentration.group
        )?_c('feather-icon',{attrs:{"icon":"ArrowUpRightIcon"}}):(
          _vm.userData.program.groups.concentration.change[
            _vm.userData.program.groups.concentration.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.concentration.change[
            _vm.userData.program.groups.concentration.change.length - 1
          ] < _vm.userData.program.groups.concentration.group
        )?_c('feather-icon',{attrs:{"icon":"ArrowDownRightIcon"}}):(
          _vm.userData.program.groups.concentration.change[
            _vm.userData.program.groups.concentration.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.concentration.change[
            _vm.userData.program.groups.concentration.change.length - 1
          ] == _vm.userData.program.groups.concentration.group
        )?_c('feather-icon',{attrs:{"icon":"CodeIcon"}}):_vm._e()],1):_vm._e()],1),_c('li',{staticClass:"print-group-list-item"},[_c('b',[_vm._v(_vm._s(_vm.$t("Memory"))+":")]),(_vm.userData.program.groups.memory.group)?_c('b-badge',{staticClass:"ml-1",attrs:{"id":"memory-print","variant":("light-" + (_vm.resolveUserRoleVariant(
        _vm.numberToGrade(_vm.userData.program.groups.memory.group)
      )))}},[_c('b-tooltip',{attrs:{"target":"memory-print","triggers":"hover","custom-class":"group-tooltip-wrapper"}},[_c('b-row',{staticClass:"justify-content-center"},_vm._l((_vm.userData.program.groups.memory.lastUpdate),function(exercise,index){return _c('b-badge',{key:'mem' + index,staticStyle:{"margin":"5px"},attrs:{"variant":exercise.indexOf('Ø') != -1
                  ? 'dark'
                  : exercise.indexOf('F') != -1
                  ? 'danger'
                  : exercise.indexOf('E') != -1
                  ? 'warning'
                  : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                  ? 'info'
                  : 'success'}},[_vm._v(" "+_vm._s(exercise)+" ")])}),1)],1),_vm._v(" "+_vm._s(_vm.numberToGrade(_vm.userData.program.groups.memory.group))+" "),(
          _vm.userData.program.groups.memory.change[
            _vm.userData.program.groups.memory.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.memory.change[
            _vm.userData.program.groups.memory.change.length - 1
          ] > _vm.userData.program.groups.memory.group
        )?_c('feather-icon',{attrs:{"icon":"ArrowUpRightIcon"}}):(
          _vm.userData.program.groups.memory.change[
            _vm.userData.program.groups.memory.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.memory.change[
            _vm.userData.program.groups.memory.change.length - 1
          ] < _vm.userData.program.groups.memory.group
        )?_c('feather-icon',{attrs:{"icon":"ArrowDownRightIcon"}}):(
          _vm.userData.program.groups.memory.change[
            _vm.userData.program.groups.memory.change.length - 1
          ] != 0 &&
          _vm.userData.program.groups.memory.change[
            _vm.userData.program.groups.memory.change.length - 1
          ] == _vm.userData.program.groups.memory.group
        )?_c('feather-icon',{attrs:{"icon":"CodeIcon"}}):_vm._e()],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }