<template>
  <div>
    <!-- <b-table :items="items" :fields="fields" striped responsive>
            <template #cell(show_details)="row">
              <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{ row.detailsShowing ? "Hide" : "Show" }}</span>
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col md="4" class="mb-1"> <strong>Full Name : </strong>{{ row.item.full_name }} </b-col>
                  <b-col md="4" class="mb-1"> <strong>Post : </strong>{{ row.item.post }} </b-col>
                  <b-col md="4" class="mb-1"> <strong>Email : </strong>{{ row.item.email }} </b-col>
                  <b-col md="4" class="mb-1"> <strong>City : </strong>{{ row.item.city }} </b-col>
                  <b-col md="4" class="mb-1"> <strong>Salary : </strong>{{ row.item.salary }} </b-col>
                  <b-col md="4" class="mb-1"> <strong>Age : </strong>{{ row.item.age }} </b-col>
                </b-row>

                <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails"> Hide Details </b-button>
              </b-card>
            </template>

            <template #cell(avatar)="data">
              <b-avatar :src="data.value" />
            </template>

            <template #cell(exercise_type)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(modify)>
              <b-button variant="primary">
                <feather-icon icon="EditIcon" size="1x" />
              </b-button>
            </template>
          </b-table> -->
    <!-- input search -->
    <b-row class="custom-search d-flex flex-row justify-content-between mb-1">
      <b-col>
        <div class="d-flex">
          <b-button variant="primary" @click="showPrint = !showPrint" id="create-session" :disabled="
              rows.filter((row) => row.solved != undefined && !row.solved).length > 0
            ">
            <feather-icon size="1x" icon="PlusIcon" />
            <span class="ml-1">{{ $t("Create Set") }}</span>
            <!-- <span>{{ rows.filter((row) => !row.solved).length }}</span> -->
          </b-button>
        </div>
      </b-col>
      <!-- <b-col>
              <b-form-group class="d-flex flex-row justify-content-end">
                <div class="d-flex align-items-center">
                  <label class="mr-1">Arama</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Ara..."
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </b-col> -->
    </b-row>
    <!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="tourStart" v-b-tooltip.hover
      title="Default variant">
      {{ $t("Start Tour") }}
    </b-button> -->
    <!-- table -->
    <vue-good-table :key="renderKey" :columns="columns" :rows="rows" :rtl="direction" style-class="vgt-table condensed"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :group-options="{
        enabled: true,
        collapsable: true,
      }" row-style-class="exercise-row">
      <template slot="table-header-row" slot-scope="props">
        <span v-if="props.column.field === 'uID'" class="seans-header">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary">
            {{ $t("Session") }} {{ props.row.id + 1 }}
          </b-button>
        </span>
        <span v-else-if="props.column.field == 'action'">
          <!-- v-b-tooltip.hover.v-primary
                  title="Egzersiz Sonuçlarını Gir" -->
          <b-button v-if="!props.row.solved" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm"
            @click="sendID(props.row.id, `seans`)" class="m-1" v-b-tooltip.hover.v-primary
            :title="$t('Input Exercise Results')">
            <feather-icon icon="EditIcon" size="12" class="text-body align-left feather-white" />
            <!-- <span class="text-nowrap uid-text">Sonuç Gir</span> -->
          </b-button>
          <b-link :to="{
              name: 'print-exercise',
              params: { id: `${$route.params.id}`, amountp: 0 },
              query: { reprint: true, session: props.row.id + 1 },
            }" target="_blank">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" v-show="!props.row.solved"
              v-b-tooltip.hover.v-primary :title="$t('Reprint')">
              <feather-icon icon="RotateCwIcon" size="12" class="text-body align-left feather-white" />
              <span class="text-nowrap uid-text"></span>
            </b-button>
          </b-link>
          <b-link :to="{
              name: 'print-exercise',
              params: { id: `${$route.params.id}`, amountp: 0 },
              query: { reprint: true, session: props.row.id + 1, answer: true },
            }" target="_blank" type="button">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="m-1"
              v-show="!props.row.solved" v-b-tooltip.hover.v-primary :title="$t('Show Answer Sheets')">
              <feather-icon icon="KeyIcon" size="12" class="text-body align-left feather-white" />
              <!-- <span class="text-nowrap uid-text"></span> -->
            </b-button>
          </b-link>
          <b-link :to="{
              name: 'print-exercise',
              params: { id: `${$route.params.id}`, amountp: 6 },
              query: { session: props.row.id + 1 },
            }" target="_blank">
            <!-- class="m-1" -->
            <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    v-show="props.row.id == rows.length - 1 && props.row.children.length < 12"
                    v-b-tooltip.hover.v-primary
                    title="Seansa Egzersiz Ekle"
                    @click="sendComplete()"
                  >
                    <feather-icon
                      icon="FilePlusIcon"
                      size="12"
                      class="text-body align-left feather-white"
                    />
                  </b-button> -->
          </b-link>
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <div v-if="props.column.field === 'uID'" class="text-nowrap">
          <span v-if="props.row.uID" class="text-nowrap">{{ $t("Exercise Code") }}: <b-badge variant="info">
              <span class="uid-text">{{ props.row.uID }}</span>
            </b-badge>
          </span>
        </div>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>
        <div v-else-if="props.column.field === 'group'" class="text-nowrap">
          <b-badge :variant="statusVariantTwo(props.row.base.name)">
            <span class="text-nowrap uid-text">{{ props.row.base.name.toUpperCase()
              }}</span>
          </b-badge>
        </div>
        <div v-else-if="props.column.field === 'time'" class="text-nowrap">
          <b-button size="sm" :variant="statusVariantLetter(props.row.time)">
            <span class="text-nowrap uid-text">
              <feather-icon icon="ClockIcon" size="12" class="feather-white" />
              {{ props.row.time }}
            </span>
          </b-button>
        </div>
        <div v-else-if="props.column.field === 'mistake'" class="text-nowrap">
          <b-button size="sm" :variant="statusVariantLetter(props.row.mistake)">
            <span class="text-nowrap uid-text">
              <feather-icon icon="CheckCircleIcon" size="12" class="feather-white" />
              {{ props.row.mistake }}
            </span>
          </b-button>
        </div>
        <div v-else-if="props.column.field === 'result'" class="text-nowrap">
          <b-button size="sm" :variant="statusVariantLetter(props.row.result)">
            <span class="text-nowrap uid-text">
              <feather-icon icon="UserCheckIcon" size="12" class="feather-white" />
              {{ props.row.result }}
            </span>
          </b-button>
        </div>
        <!-- <div v-else-if="props.column.field === 'solved'" class="text-nowrap">
                <span class="text-nowrap" v-if="props.row.solved">Sonuç Girildi</span>
                <span class="text-nowrap" v-if="!props.row.solved">Sonuç Girilmedi </span>
              </div> -->
        <div v-else-if="props.column.field === 'createdAt'" class="text-nowrap">
          <span class="text-nowrap">{{ new Date(props.row.updatedAt).toLocaleDateString($i18n.locale)
            }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <!-- <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item>
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown> -->
            <!-- <b-button
                    v-if="!props.row.solved"
                    variant="outline-primary"
                    size="sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    @click="sendID(props.row.uID, 'uID')"
                  >
                    <feather-icon icon="Edit2Icon" size="12" class="text-body align-left" />
                    <span class="text-nowrap uid-text">Sonuç Gir</span>
                  </b-button> -->
            <b-link :to="{
                name: 'print-exercise',
                params: { id: `${$route.params.id}`, amountp: 0 },
                query: { reprint: true, exercise: props.row.uID },
              }" target="_blank">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" v-show="!props.row.solved"
                :disabled="isActive" class="mr-1" v-b-tooltip.hover.v-primary :title="$t('Reprint')">
                <feather-icon icon="RotateCwIcon" size="12" class="text-body align-left feather-white" />
                <!-- <span class="text-nowrap uid-text">Tekrar Yazdır </span> -->
              </b-button>
            </b-link>
            <b-link :to="{
                name: 'print-exercise',
                params: { id: `${$route.params.id}`, amountp: 0 },
                query: { reprint: true, exercise: props.row.uID, answer: true },
              }" target="_blank">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" v-show="!props.row.solved"
                v-b-tooltip.hover.v-primary :title="$t('Show Answer Sheet')">
                <feather-icon icon="KeyIcon" size="12" class="text-body align-left feather-white" />
                <!-- <span class="text-nowrap uid-text">Cevapları Yazdır </span> -->
              </b-button>
            </b-link>
            <b-button v-if="props.row.solved" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" size="sm">
              <feather-icon icon="CheckIcon" size="12" class="feather-white" />
              <span class="text-nowrap uid-text ml-1">{{ $t("Answers Complete") }}</span>
            </b-button>
          </span>
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t("Showing") }} </span>
            <b-form-select v-model="pageLength" :options="['10', '20', '50']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap">
              {{ $t("Total Exercises") }}: {{ props.total }}
            </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-row class="mt-1 d-none">
      <b-col>
        <UsersExerciseEntry ref="getref" subjectID="-" @sendComplete="sendComplete" />
      </b-col>
    </b-row>
    <div>
      <PrintExerciseMain :user-i-d="this.$route.params.id" :show-print="showPrint" @printed="sendComplete" />
    </div>
    <!-- <AppTour :steps="steps" /> -->
  </div>
</template>

<script lang="js">
import PrintExerciseMain from "@/views/apps/user/exercise-print/PrintExerciseMain.vue";
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BAlert,
  BDropdown,
  BDropdownItem,
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BLink,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import UsersExerciseEntry from "@/views/apps/user/users-exercise/UsersExerciseEntry.vue";
import AppTour from "@core/components/app-tour/AppTour.vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";

const {
  axiosRoutes: { exercise: path },
} = require("/G_CONFIG");

export default {
  props: {
    program: {
      type: Object,
      required: false,
    },
    isActive:{
      type: Boolean,
      required: false,
    }
  },
  components: {
    AppTour,
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BLink,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BPagination,
    VueGoodTable,
    UsersExerciseEntry,
    PrintExerciseMain,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showPrint: false,
      pageLength: 10,
      dir: false,
      renderKey:0,
      /* @params
      uid
      group - type.name
      time
      mistake
      result
      solved
      */
      columns: [
        {
          label: this.$t("Exercisse Code"),
          field: "uID",
          tdClass: "text-center",
          thClass: "text-center",
          sortable:false,
          // filterOptions: {
          //   enabled: true,
          //   placeholder: this.$t("Exercisse Code"),
          // },
        },
        {
          label: this.$t("Type"),
          field: "group",
          width: "10%",
          tdClass: "text-center",
          thClass: "text-center",
          sortable:false,
          // sortFn: (a, b) => {
          //   if (a.base.name < b.base.name) return -1;
          //   if (a.base.name > b.base.name) return 1;
          //   return 0;
          // },
          // filterOptions: {
          //   enabled: true,
          //   placeholder: this.$t("Type"),
          // },
        },
        {
          label: this.$t("Date"),
          field: "createdAt",
          tdClass: "text-center",
          thClass: "text-center",
          sortable:false,
          // filterOptions: {
          //   enabled: true,
          //   placeholder: this.$t("Date"),
          // },
        },
        {
          label: this.$t("Time"),
          field: "time",
          tdClass: "text-center",
          thClass: "text-center",
          width: "10%",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: this.$t("Time"),
          // },
        },
        {
          label: this.$t("Mistake"),
          field: "mistake",
          tdClass: "text-center",
          thClass: "text-center",
          width: "10%",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: this.$t("Mistake"),
          // },
        },
        {
          label: this.$t("Result"),
          field: "result",
          tdClass: "text-center",
          thClass: "text-center",
          width: "10%",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: this.$t("Result"),
          // },
        },
        {
          label: this.$t("Action"),
          field: "action",
          tdClass: "text-center",
          thClass: "text-center",
          sortable:false,
        },
      ],
      rows: [],
      searchTerm: "",
      steps: [
        {
          target: "#create-session",
          header: {
            title: "Diko Öğretici - Seans Başlatma",
          },
          content:
            "Danışan Ekle butonuna tıklayarak danışanınızı hızlıca ekleyebilirsiniz.	👈",
          params: {
            placement: "top",
          },
        },
        {
          target: "#create-session",
          header: {
            title: "Diko Öğretici - Seans Başlatma",
          },
          content:
            "Danışan Ekle butonuna tıklayarak danışanınızı hızlıca ekleyebilirsiniz.	👈",
          params: {
            placement: "top",
          },
        },
      ],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        dikkat: "light-primary",
        hafiza: "light-danger",
        konsantrasyon: "warning",
        mantik: "light-success",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    statusVariantTwo() {
      const statusColorTwo = {
        /* eslint-disable key-spacing */
        dikkat: "danger",
        hafiza: "warning",
        konsantrasyon: "primary",
        mantik: "success",
        Applied: "info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColorTwo[status];
    },
    statusVariantLetter() {
      const statusColorLetter = {
        /* eslint-disable key-spacing */
        A: "success",
        B: "info",
        C: "primary",
        D: "warning",
        E: "danger",
        F: "dark",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColorLetter[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  async created() {
    this.renderKey++;
    this.fetchData();
    // console.log(this.rows);

    /*  this.rows = table.tableBasic;
    console.log("rows", table.tableBasic); */
  },

  async mounted() {
    /*    console.log(`${path.list + this.$route.params.id}`);
    const response = await axios.get(`${path.list + this.$route.params.id}`);
    this.items = response.data;
    console.log(response.data); */
  },
  methods: {
    tourStart() {
      this.$tours.vuexyTour.start();
    },
    sendID(id, seansType) {
      console.log(id);
      this.$refs.getref.sendID(id, seansType);
    },
    showPrintF() {
      this.showPrint = true;
      /* this.showPrint = false; */
    },
    async sendComplete() {
      this.showPrint = false;
      document.getElementById("loading-bg").style.display = "block";
      setTimeout(()=>{
        // console.log('refetching');
        // let res = await axios.get(`${path.list + this.$route.params.id}`);
        // console.log(res.data);
        // this.rows = res.data.reverse();
        this.fetchData(true);
      },1000)
      // console.log(this.rows);
    },
    async fetchData(isPrint=false){
    document.getElementById("loading-bg").style.display = "block";
    let res;
    do{
      res = await axios.get(`${path.list + this.$route.params.id}`);
    } while(isPrint && !res.data[0].children)

    document.getElementById("loading-bg").style.display = "none";
    // console.table(res.data)
    this.rows = res.data.reverse();
    await this.$nextTick();
    if(isPrint && this.rows.length > 0 && this.rows[0].children)
    this.renderKey++;
    }
  },
};
</script>
<!--<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
/* .seans-header {
  margin-left: 10px;
} */
.uid-text {
  color: #fff !important;
}
</style> -->
<style scoped>
.feather-white {
  color: #fff !important;
}
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/tour.scss";
</style>
