import { render, staticRenderFns } from "./UsersExerciseDetails.vue?vue&type=template&id=35d5c820&scoped=true&"
import script from "./UsersExerciseDetails.vue?vue&type=script&lang=js&"
export * from "./UsersExerciseDetails.vue?vue&type=script&lang=js&"
import style0 from "./UsersExerciseDetails.vue?vue&type=style&index=0&id=35d5c820&scoped=true&lang=css&"
import style1 from "./UsersExerciseDetails.vue?vue&type=style&index=1&id=35d5c820&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d5c820",
  null
  
)

export default component.exports