<template>
  <div>
    <b>{{ $t("Subject") }}: {{ userData.name.toUpperCase() }}</b>
    <ul class="group-list-on-print">
      <li class="print-group-list-item">
        <b>{{ $t("Attention") }}:</b>
        <b-badge v-if="userData.program.groups.attention.group" id="attention-print" class="ml-1" :variant="`light-${resolveUserRoleVariant(
          numberToGrade(userData.program.groups.attention.group)
        )}`">
          <b-tooltip target="attention-print" custom-class="group-tooltip-wrapper" triggers="hover">
            <b-row class="justify-content-center">
              <b-badge style="margin: 5px" v-for="(exercise, index) in userData.program.groups.attention.lastUpdate"
                :variant="
                  exercise.indexOf('Ø') != -1
                    ? 'dark'
                    : exercise.indexOf('F') != -1
                    ? 'danger'
                    : exercise.indexOf('E') != -1
                    ? 'warning'
                    : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                    ? 'info'
                    : 'success'
                " :key="'att' + index">
                {{ exercise }}
              </b-badge>
            </b-row>
          </b-tooltip>
          {{ numberToGrade(userData.program.groups.attention.group) }}
          <feather-icon v-if="
            userData.program.groups.attention.change[
              userData.program.groups.attention.change.length - 1
            ] != 0 &&
            userData.program.groups.attention.change[
              userData.program.groups.attention.change.length - 1
            ] > userData.program.groups.attention.group
          " icon="ArrowUpRightIcon"></feather-icon>
          <feather-icon v-else-if="
            userData.program.groups.attention.change[
              userData.program.groups.attention.change.length - 1
            ] != 0 &&
            userData.program.groups.attention.change[
              userData.program.groups.attention.change.length - 1
            ] < userData.program.groups.attention.group
          " icon="ArrowDownRightIcon"></feather-icon>
          <feather-icon v-else-if="
            userData.program.groups.attention.change[
              userData.program.groups.attention.change.length - 1
            ] != 0 &&
            userData.program.groups.attention.change[
              userData.program.groups.attention.change.length - 1
            ] == userData.program.groups.attention.group
          " icon="CodeIcon"></feather-icon>
        </b-badge>
      </li>
      <li class="print-group-list-item">
        <b>{{ $t("Logic") }}:</b>
        <b-badge v-if="userData.program.groups.logic.group" class="ml-1" id="logic-print" :variant="`light-${resolveUserRoleVariant(
          numberToGrade(userData.program.groups.logic.group)
        )}`">
          <b-tooltip target="logic-print" triggers="hover" custom-class="group-tooltip-wrapper">
            <b-row class="justify-content-center">
              <b-badge style="margin: 5px" v-for="(exercise, index) in userData.program.groups.logic.lastUpdate"
                :variant="
                  exercise.indexOf('Ø') != -1
                    ? 'dark'
                    : exercise.indexOf('F') != -1
                    ? 'danger'
                    : exercise.indexOf('E') != -1
                    ? 'warning'
                    : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                    ? 'info'
                    : 'success'
                " :key="'logic' + index">
                {{ exercise }}
              </b-badge>
            </b-row>
          </b-tooltip>
          {{ numberToGrade(userData.program.groups.logic.group) }}
          <feather-icon v-if="
            userData.program.groups.logic.change[
              userData.program.groups.logic.change.length - 1
            ] != 0 &&
            userData.program.groups.logic.change[
              userData.program.groups.logic.change.length - 1
            ] > userData.program.groups.logic.group
          " icon="ArrowUpRightIcon"></feather-icon>
          <feather-icon v-else-if="
            userData.program.groups.logic.change[
              userData.program.groups.logic.change.length - 1
            ] != 0 &&
            userData.program.groups.logic.change[
              userData.program.groups.logic.change.length - 1
            ] < userData.program.groups.logic.group
          " icon="ArrowDownRightIcon"></feather-icon>
          <feather-icon v-else-if="
            userData.program.groups.logic.change[
              userData.program.groups.logic.change.length - 1
            ] != 0 &&
            userData.program.groups.logic.change[
              userData.program.groups.logic.change.length - 1
            ] == userData.program.groups.logic.group
          " icon="CodeIcon"></feather-icon>
        </b-badge>
      </li>
      <li class="print-group-list-item">
        <b>{{ $t("Concentration") }}:</b>
        <b-badge v-if="userData.program.groups.concentration.group" class="ml-1" id="concentration-print" :variant="`light-${resolveUserRoleVariant(
          numberToGrade(userData.program.groups.concentration.group)
        )}`">
          <b-tooltip target="concentration-print" triggers="hover" custom-class="group-tooltip-wrapper">
            <b-row class="justify-content-center">
              <b-badge style="margin: 5px" v-for="(exercise, index) in userData.program.groups.concentration
              .lastUpdate" :variant="
                    exercise.indexOf('Ø') != -1
                      ? 'dark'
                      : exercise.indexOf('F') != -1
                      ? 'danger'
                      : exercise.indexOf('E') != -1
                      ? 'warning'
                      : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                      ? 'info'
                      : 'success'
                  " :key="'conc' + index">
                {{ exercise }}
              </b-badge>
            </b-row>
          </b-tooltip>
          {{ numberToGrade(userData.program.groups.concentration.group) }}
          <feather-icon v-if="
            userData.program.groups.concentration.change[
              userData.program.groups.concentration.change.length - 1
            ] != 0 &&
            userData.program.groups.concentration.change[
              userData.program.groups.concentration.change.length - 1
            ] > userData.program.groups.concentration.group
          " icon="ArrowUpRightIcon"></feather-icon>
          <feather-icon v-else-if="
            userData.program.groups.concentration.change[
              userData.program.groups.concentration.change.length - 1
            ] != 0 &&
            userData.program.groups.concentration.change[
              userData.program.groups.concentration.change.length - 1
            ] < userData.program.groups.concentration.group
          " icon="ArrowDownRightIcon"></feather-icon>
          <feather-icon v-else-if="
            userData.program.groups.concentration.change[
              userData.program.groups.concentration.change.length - 1
            ] != 0 &&
            userData.program.groups.concentration.change[
              userData.program.groups.concentration.change.length - 1
            ] == userData.program.groups.concentration.group
          " icon="CodeIcon"></feather-icon>
        </b-badge>
      </li>
      <li class="print-group-list-item">
        <b>{{ $t("Memory") }}:</b>
        <b-badge v-if="userData.program.groups.memory.group" class="ml-1" id="memory-print" :variant="`light-${resolveUserRoleVariant(
          numberToGrade(userData.program.groups.memory.group)
        )}`">
          <b-tooltip target="memory-print" triggers="hover" custom-class="group-tooltip-wrapper">
            <b-row class="justify-content-center">
              <b-badge style="margin: 5px" v-for="(exercise, index) in userData.program.groups.memory.lastUpdate"
                :variant="
                  exercise.indexOf('Ø') != -1
                    ? 'dark'
                    : exercise.indexOf('F') != -1
                    ? 'danger'
                    : exercise.indexOf('E') != -1
                    ? 'warning'
                    : exercise.indexOf('C') != -1 || exercise.indexOf('D') != -1
                    ? 'info'
                    : 'success'
                " :key="'mem' + index">
                {{ exercise }}
              </b-badge>
            </b-row>
          </b-tooltip>
          {{ numberToGrade(userData.program.groups.memory.group) }}
          <feather-icon v-if="
            userData.program.groups.memory.change[
              userData.program.groups.memory.change.length - 1
            ] != 0 &&
            userData.program.groups.memory.change[
              userData.program.groups.memory.change.length - 1
            ] > userData.program.groups.memory.group
          " icon="ArrowUpRightIcon"></feather-icon>
          <feather-icon v-else-if="
            userData.program.groups.memory.change[
              userData.program.groups.memory.change.length - 1
            ] != 0 &&
            userData.program.groups.memory.change[
              userData.program.groups.memory.change.length - 1
            ] < userData.program.groups.memory.group
          " icon="ArrowDownRightIcon"></feather-icon>
          <feather-icon v-else-if="
            userData.program.groups.memory.change[
              userData.program.groups.memory.change.length - 1
            ] != 0 &&
            userData.program.groups.memory.change[
              userData.program.groups.memory.change.length - 1
            ] == userData.program.groups.memory.group
          " icon="CodeIcon"></feather-icon>
        </b-badge>
      </li>
      <!-- <li>
      <b>{{ $t('Email') }}:</b> {{ userData.email }}
    </li>
    <li>
      <b>{{ $t('Phone') }}:</b> {{ userData.phone }}
    </li>
    <li>
      <b>{{ $t('Address') }}:</b> {{ userData.address }}
    </li> -->
    </ul>
  </div>
</template>
<script>
import { BBadge, BRow, BTooltip, VBTooltip } from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BRow,
    BTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    numberToGrade(number) {
      const values = ["A", "B", "C", "D", "E"];
      return values[number - 1];
    },
    resolveUserRoleVariant: (role) => {
      // if (role === "subscriber") return "primary";
      if (role === "E") return "danger";
      if (role === "A") return "success";
      if (role === "B") return "info";
      if (role === "RED") return "danger";
      if (role === "C") return "primary";
      if (role === "D") return "warning";
      if (role === undefined) return "secondary";
      if (role === "phone") return "success";
      return "primary";
    },
  },
};
</script>
<style scoped>
.group-list-on-print {
  width: 100%;
  margin-top: 10px;
}
.print-group-list-item {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
</style>
