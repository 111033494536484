<template>
  <b-card no-body class="border-primary">
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        {{ $t("Last Test Information") }}
      </h5>
      <small class="text-muted w-100">Mart 22, 2022</small>
    </b-card-header>
    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">{{$t('True:')}}</span>
        </li>
        <li class="my-25">
          <span class="align-middle">{{$t('False:')}}</span>
        </li>
        <li>
          <span class="align-middle">{{$t('Point:')}}</span>
        </li>
        <li>
          <span class="align-middle">{{$t('Group:')}}</span>
        </li>
      </ul>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block
        :to="{ name: 'test-testing',params: {} }">
        <div class="d-flex justify-content-center align-items-center">
          <feather-icon size="1.5x" icon="ActivityIcon" class="mr-1"></feather-icon><span>{{ $t('Apply Test') }}</span>
        </div>
      </b-button>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block
        :to="{ name: 'exercises-detail',params: {} }">
        <div class="d-flex justify-content-center align-items-center">
          <feather-icon size="1.5x" icon="AlignJustifyIcon" class="mr-1"></feather-icon><span>{{ $t('Sessions Processes') }}</span>
        </div>
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {BCard,BCardHeader,BCardBody,BBadge,BButton,BLink} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BLink,
  },
  computed: {
    userID() {
      return this.$route.params.id;
    },
  },
  methods: {
  },
};
</script>

<style>
</style>
