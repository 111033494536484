<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">{{ $t("Error") + " " + $t("Fetching Data") }}</h4>
      <div class="alert-body">
        {{ $t("No Subject found") }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          {{ $t("Subjects List") }}
        </b-link>
      </div>
    </b-alert>
    <template v-if="userData">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col cols="12" xl="9" lg="8" md="7">
          <user-view-user-info-card :user-data="userData" />
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <test-card
            :test-data="testData"
            :program-data="userData.program"
            :grade="userData.subjectClass"
            :isFirm="userData.isFirmSubject"
            :isActive="userData.active"
            :subjectID="userData._id"
          />
        </b-col>
      </b-row>
    </template>
    <!-- Second Row -->
    <user-exercise-details :isActive="userData.active" />
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import UserViewUserInfoCard from "@/views/apps/user/users-view/UserViewUserInfoCard.vue";
import UserViewUserPlanCard from "@/views/apps/user/users-view/UserViewUserPlanCard.vue";
import TestCard from "@/views/apps/user/users-view/TestCard.vue";
import UserViewUserTimelineCard from "@/views/apps/user/users-view/UserViewUserTimelineCard.vue";
import UserViewUserPermissionsCard from "@/views/apps/user/users-view/UserViewUserPermissionsCard.vue";
import ChartsjsLineChart from "@/views/charts-and-maps/charts/chartjs/ChartjsLineChart.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import userStoreModule from "../userStoreModule";
import UserExerciseDetails from "@/views/apps/user/users-exercise/UsersExerciseDetails.vue";
import userData from "@/store/user-data";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    TestCard,
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    ChartsjsLineChart,
    StatisticCardVertical,
    StatisticCardWithAreaChart,

    // InvoiceList,
    UserExerciseDetails,
  },
  data() {
    return {
      subscribersGained: {
        series: [
          {
            name: "Dikkat",
            data: [28, 40, 36, 52, 38, 60, 90],
          },
        ],
      },
      subscribersGained2: {
        series: [
          {
            name: "Konsantrasyon",
            data: [38, 40, 46, 52, 48, 55, 57],
          },
        ],
      },
      subscribersGained3: {
        series: [
          {
            name: "Mantık-Muhakeme",
            data: [18, 20, 26, 22, 28, 35, 30],
          },
        ],
      },
    };
  },
  created() {},
  setup() {
    const userData = ref(null);
    const testData = ref(null);
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data.subject;
        testData.value = response.data.testData;
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
      testData,
    };
  },
};
</script>

<style scoped>
.client-rows {
  margin-top: -20px !important;
}
</style>
